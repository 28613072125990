/* eslint-disable react-hooks/exhaustive-deps */
import { LastThingEvent, Thing, ThingType } from '@eagle/core-data-types';
import { BoldMatchedText, CacheDataTypes, EventAddress, FetchOneOfAll, MapPosition, testid, trackEvent, Undefinable, useCustomRoutes, usePromise, validateLocationType } from '@eagle/react-common';
import { Box, Link, Skeleton, Stack } from '@mui/material';
import { DateTime } from 'luxon';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useMap } from 'react-leaflet';
import { useHref } from 'react-router-dom';

interface ThingLastLocationProps {
  getThingLastLocation: (id: string) => Promise<Undefinable<LastThingEvent>>;
  handleDrawerClose: () => void;
  onItemClicked: (item: string, location: MapPosition) => void;
  searchQuery?: string;
  thing: Thing;
  index?: number;
}

interface CommonProps {
  children: JSX.Element;
  href: string;
  locationExist: boolean;
}

const LinkWrapper: FC<CommonProps> = ({ children, href, locationExist }) => {
  if (!locationExist) return <>{children}</>;
  return (
    <Link href={href} underline="none">
      {children}
    </Link>
  );
};

export const ThingLastLocationRow: FC<ThingLastLocationProps> = ({ getThingLastLocation, handleDrawerClose, onItemClicked, searchQuery, thing, index }) => {
  const { t } = useTranslation(['common', 'track']);
  const { thing: customRoutesThing, things: customRoutesThings } = useCustomRoutes();
  const href = useHref(thing ? `/${customRoutesThing}/${thing._id}` : `/${customRoutesThings}`);
  const map = useMap();
  const center = map.getCenter();
  const position = {
    alt: map.getZoom(),
    lat: center.lat,
    lng: center.lng,
    timeStamp: DateTime.now().toMillis(),
  };

  const [lastThingEvent, lastThingEventError, lastThingEventStatus] = usePromise<Undefinable<LastThingEvent>>(
    () => thing ? getThingLastLocation(thing._id) : Promise.resolve(undefined),
    [thing],
  );

  const location = validateLocationType(() => lastThingEvent?.data.location);
  const locationExist = !!location?.address;
  const isLoading = lastThingEventStatus !== 'resolved';
  const loadingComponent = <Skeleton height={'20px'} />;

  if (!thing || lastThingEventError) return <></>;

  return (
    <Box
      key={thing._id}
      data-testid={testid`thing-map-search-location-${thing._id}`}
      onClick={(e) => {
        if (!locationExist || e.ctrlKey || e.shiftKey) return;
        e.preventDefault();
        onItemClicked(thing._id, position);
        handleDrawerClose();
        trackEvent('keyword_search', 'selected_item', 'thing_map', { 'selected_item': index ? (index + 1) : 1, 'thing_id': thing._id });
      }}
      sx={{ cursor: locationExist ? 'pointer' : 'default', my: 1 }}
    >
      <FetchOneOfAll
        dataType={CacheDataTypes.THING_TYPE}
        id={thing.thingTypeId}
        renderFactory={(thingType: ThingType) => (
          <LinkWrapper href={href} locationExist={locationExist}>
            <Box color="initial">
              <Stack direction="row" spacing={1}>
                <BoldMatchedText query={searchQuery ?? ''} text={thing.display} />
                <BoldMatchedText query={searchQuery ?? ''} sx={{ color: 'text.secondary' }} text={thingType.display} />
              </Stack>
              {isLoading
                ? loadingComponent
                : lastThingEvent
                && <EventAddress
                  addressUnavailableText={t('common:component.map.hint.no-location')}
                  loadingComponent={loadingComponent}
                  location={location}
                  variant="caption"
                />
              }
              {!locationExist && !isLoading
                && <Link href={href} sx={{ ml: 1, pointerEvents: 'auto', whiteSpace: 'nowrap' }} variant="body2">
                  {t('track:page.thing-map.view-thing-detail.action')}
                </Link>
              }
            </Box>
          </LinkWrapper>
        )}
      />
    </Box>
  );
};
