/* eslint-disable react-hooks/exhaustive-deps */
import { LastThingEvent } from '@eagle/core-data-types';
import { ErrorMessage, MAP_FLY_TO_DURATION, Undefinable, useCustomRoutes, useMapContext, usePromise, validateLocationType } from '@eagle/react-common';
import L from 'leaflet';
import { useSnackbar } from 'notistack';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMap } from 'react-leaflet';
import { Location as ReactRouterLocation, useLocation, useNavigate, useParams } from 'react-router-dom';

interface Props {
  getThingLastLocation: (id: string) => Promise<Undefinable<LastThingEvent>>;
}

export interface LocationState extends ReactRouterLocation {
  previousLocations?: {
    pathname: string;
  };
  shouldFly?: boolean;
  shouldSetView?: boolean;
}

const CENTER_TOLERANCE = 10;

export const ThingMapNavigator: FC<Props> = ({ getThingLastLocation }) => {
  const { things } = useCustomRoutes();
  const { enqueueSnackbar } = useSnackbar();
  const { savedSearchPosition, setSavedSearchPosition } = useMapContext();
  const { state } = useLocation() as { state: LocationState };
  const { t } = useTranslation(['common', 'track']);
  const { thingId } = useParams();
  const map = useMap();
  const navigate = useNavigate();
  const shouldFly = state?.shouldFly ?? true;
  const shouldSetView = state?.shouldSetView ?? false;

  const [lastThingEvent, lastThingEventError, lastThingEventStatus] = usePromise<Undefinable<LastThingEvent>>(
    () => thingId ? getThingLastLocation(thingId) : Promise.resolve(undefined),
    [thingId]
  );

  useEffect(() => {
    return () => {
      setSavedSearchPosition(null);
    };
  }, []);

  useEffect(() => {
    if (lastThingEventStatus === 'pending' || !lastThingEvent || lastThingEvent.thingId !== thingId) return;
    const location = validateLocationType(() => lastThingEvent.data.location);
    if (!location) {
      navigate(`/map/${things}`, {
        state: {
          shouldFly: false,
        },
      });
      enqueueSnackbar(t('common:component.map.hint.no-location'), { variant: 'error' });
      return;
    }

    const { latitude, longitude } = location;
    const position = new L.LatLng(latitude, longitude);

    if (position.distanceTo(map.getCenter()) < CENTER_TOLERANCE) return;

    if (shouldSetView) {
      map.setView(position, map.getMaxZoom());
      map.fire('zoomend');
      return;
    }

    if (shouldFly || !map.getBounds().contains(position)) {
      map.flyTo(position, map.getMaxZoom(), { duration: MAP_FLY_TO_DURATION });
    }
  }, [lastThingEvent, lastThingEventStatus, shouldFly, shouldSetView, thingId]);

  useEffect(() => {
    if (thingId) return;
    if (!savedSearchPosition) return;
    const { lat, lng, alt } = savedSearchPosition;
    map.flyTo(new L.LatLng(lat, lng), alt, { duration: MAP_FLY_TO_DURATION });
    setSavedSearchPosition(null);
  }, [thingId, savedSearchPosition]);

  useEffect(() => {
    if (!lastThingEventError) return;
    navigate(`/map/${things}`, {
      state: {
        shouldFly: false,
      },
    });
    enqueueSnackbar(<ErrorMessage error={lastThingEventError} />, { variant: 'error' });
  }, [lastThingEventError]);

  return null;
};
